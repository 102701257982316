<template>
  <div>
    <s-confirmation-dialog
      v-model="deleteDialogOpened"
      :title="$t('assignmentPage.deleteDialog.title').toString()"
      :content="$t('assignmentPage.deleteDialog.text').toString()"
      :confirm-action="deleteAssignment"
      :confirm-button-options="{
        text: $t('assignmentPage.deleteDialog.confirm').toString(),
        color: 'danger',
      }"
      data-test="confirm-assignment-deletion-dialog"
    />
    <v-row justify="space-between" class="font-weight-medium" style="font-size: 20px">
      <v-col
        cols="12"
        class="mb-2"
        style="justify-content: space-between; align-items: center; display: flex"
      >
        <p style="font-size: 1.375rem; font-weight: 500; line-height: 1.2; margin: 0">
          {{ $t('assignment-info') }}
        </p>
        <div v-if="updateMode && canAssignAssignment">
          <v-btn
            class="mr-0"
            color="blue darken-1"
            icon
            data-test="assignment-info-edit"
            :disabled="isAssignmentSaving"
            :href="
              !isAssignmentSaving
                ? `/courses/${$route.params.courseId}/assignments/${$route.params.assignmentId}/settings`
                : undefined
            "
            @click="openAssignmentSettings($event)"
          >
            <v-icon> {{ $i('common.edit') }}</v-icon>
          </v-btn>
        </div>
      </v-col>
      <div v-if="!courseLikeAssignment" class="no-course-likes-message">
        <p>
          {{
            $t(
              updateMode
                ? 'editAssignment.noSectionsMessage'
                : 'editAssignment.noSectionsMessageDraft'
            )
          }}
        </p>
      </div>
    </v-row>

    <div v-if="courseLikeAssignment">
      <!-- loop through assignmentInfo -->
      <div class="info-list" data-test="assignmentInfoTable">
        <div v-for="item in assignmentInfo" :key="item.label" class="info-item">
          <!-- Label -->
          <div v-if="item.extension">
            <v-tooltip top>
              <template v-slot:activator="{on: tooltip, attrs}">
                <v-badge color="success" style="padding-bottom: 2px" inline>
                  <template v-slot:badge>
                    <v-icon
                      style="font-size: 12px"
                      v-bind="attrs"
                      v-on="{...tooltip}"
                      @click="modals.showOriginalDates = true"
                    >
                      {{ $i('assignment.extensions') }}
                    </v-icon>
                  </template>
                  <span class="info-label info-label--extension">{{ item.label }}</span>
                </v-badge>
              </template>
              {{ $t('extensionToolTip') }}
            </v-tooltip>
          </div>
          <div v-else>
            <span class="info-label">{{ item.label }}</span>
          </div>
          <!-- End Label  -->

          <!-- Value -->
          <div
            v-if="item.label === $t('assignmentPage.assignCourseLikesTable.headers.grade')"
            :data-test="item.label"
            :class="getGradeColor"
          >
            <v-tooltip v-if="assignmentGrade === 'locked'" left>
              <template #activator="{attrs, on}">
                <v-icon v-bind="attrs" v-on="on">
                  {{ $i('assignment.lockedGrade') }}
                </v-icon>
              </template>
              <span class="info-value">{{ $t('grade-is-locked') }}</span>
            </v-tooltip>
            <v-progress-circular
              v-else-if="gradeIsLoading"
              indeterminate
              size="18"
              color="primary"
            />
            <span v-else class="info-value font-weight-medium">
              <assignment-grade :grade="item.value"
            /></span>
            <div v-if="isMarking && item.value" class="grade-override pt-3">
              <!-- This Is Specific To The Grading View -->
              <v-btn
                color="white"
                small
                rounded
                :href="`/assignment-grades/${item.value.id}/adjustments/create`"
              >
                <v-icon left small color="secondary"> mdi-lock-percent-open</v-icon>
                <span class="font-bold">{{ $t('assignmentGradeOverride.action') }}</span>
              </v-btn>
            </div>
          </div>
          <div
            v-else-if="
              item.label === $t('assignmentPage.assignCourseLikesTable.headers.assignmentOwner')
            "
            class="my-1"
          >
            <v-select
              v-if="canUpdateAssignment"
              :value="assignmentOwnerId"
              :items="
                ownerOptions
                  ? ownerOptions.sort((a, b) => a.name.localeCompare(b.name))
                  : ownerOptions
              "
              item-text="name"
              item-value="id"
              dense
              flat
              solo
              hide-details
              data-test="assignment-owner"
              @change="$emit('update:assignmentOwnerId', $event)"
            ></v-select>
            <span v-else>{{ ownerName }}</span>
          </div>
          <a
            v-else-if="updateMode && canAssignAssignment"
            :href="
              !isAssignmentSaving
                ? `/courses/${$route.params.courseId}/assignments/${$route.params.assignmentId}/settings`
                : undefined
            "
            @click="openAssignmentSettings($event)"
          >
            <span
              class="info-value"
              :data-test="item.label"
              :class="item.withinTwoDays && 'red--text font-weight-bold'"
              >{{ item.value }}</span
            >
          </a>
          <div v-else>
            <span
              class="info-value"
              :class="item.withinTwoDays && 'red--text font-weight-bold'"
              :data-test="item.label"
              >{{ item.value }}</span
            >
          </div>
          <!-- End Value -->
        </div>
      </div>
    </div>

    <v-row
      v-if="updateMode && (canUpdateAssignment || canAssignAssignment)"
      justify="center"
      class="mt-6 mb-0"
    >
      <v-btn
        width="90%"
        color="secondary"
        rounded
        :disabled="missingFormData.length !== 0"
        :loading="isAssignmentSaving"
        @click="saveAssignment()"
      >
        {{ $t('assignmentPage.saveAssignment') }}
      </v-btn>
    </v-row>
    <v-row v-if="updateMode && !canUpdateAssignment" justify="center" class="mt-5 mb-0">
      <v-btn
        color="secondary"
        width="90%"
        rounded
        :href="`/web/courses/${$route.params.courseId}/assignments/${$route.params.assignmentId}/tasks/first`"
      >
        {{ $t('start-quiz') }}
      </v-btn>
    </v-row>
    <v-row
      v-if="updateMode && !isNewAssignment && canGrantExtensionsFor"
      justify="center"
      class="mt-5 mb-0"
    >
      <v-btn width="90%" rounded :href="extensionsRouteTo">
        {{ $t('assignmentPage.buttons.extensions') }}
      </v-btn>
    </v-row>
    <v-row
      v-if="updateMode && !isNewAssignment && canViewGradesFor"
      justify="center"
      class="mt-5 mb-0"
    >
      <v-btn
        width="90%"
        rounded
        :href="`/courses/${course.id}/assignments/${assignment.id}/grades`"
      >
        {{ $t('assignmentPage.buttons.gradeTable') }}
      </v-btn>
    </v-row>
    <v-row
      v-if="updateMode && !isNewAssignment && canUpdateAssignment"
      justify="center"
      class="mt-5 mb-0"
    >
      <v-btn
        width="90%"
        rounded
        color="danger"
        outlined
        class="mr-4 mb-1"
        :disabled="!canDeleteAssignment"
        data-test="delete-assignment"
        @click="deleteDialogOpened = true"
      >
        {{ $t('assignmentPage.buttons.delete') }}
        <v-tooltip v-if="!canDeleteAssignment" bottom data-test="cannot-delete-assignment">
          <template v-slot:activator="{on, attrs}">
            <v-icon
              right
              v-bind="attrs"
              class="disabled-icon"
              data-test-tooltip-activator="cannot-delete-assignment"
              v-on="on"
            >
              {{ $i('common.info') }}
            </v-icon>
          </template>
          <span data-test-tooltip-content-for="cannot-delete-assignment">
            {{ $t('assignmentPage.buttons.deleteTooltip') }}
          </span>
        </v-tooltip>
      </v-btn>
    </v-row>
    <v-row v-if="updateMode && canUpdateAssignment && missingFormData.length > 0" class="mt-6">
      <v-col cols="12">
        <p class="pl-4">
          {{ $t('newAssignmentValidation.message') }}
        </p>
        <v-list-item v-for="data in missingFormData" :key="data.missingData" two-line>
          <v-list-item-content>
            <v-list-item-title class="red--text accent-4 text-bold">
              {{ $t(`newAssignmentValidation.errorTypes.${data.missingData}`) }}
            </v-list-item-title>
            <v-list-item-content class="red--text accent-4">
              {{ $t(`newAssignmentValidation.descriptions.${data.missingData}`) }}
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-dialog v-if="assignmentExtension" v-model="modals.showOriginalDates" width="500px">
      <!--
        # Student Only

        This dialog is shown to Students if they click on the icon next to the due date.
        -->
      <v-card>
        <v-card-title class="headline success white--text pb-2">
          {{ $t('assignmentExtended') }}
        </v-card-title>

        <v-card-text>
          <span style="font-size: 18px">{{ $t('extendedDueDateMessage') }}</span>
          <br /><br />

          <template v-if="assignmentExtension.newStartDate">
            <span class="font-weight-bold success--text">{{ $t('original-start-date') }}:</span>
            {{ `${moment(courseLikeAssignment.startDate).format('ddd, MMM DD [at] h:mm a')}`
            }}<br />
            <span class="font-weight-bold success--text">{{ $t('new-start-date') }}: </span>
            {{ `${moment(assignmentExtension.newStartDate).format('ddd, MMM DD [at] h:mm a')}` }}

            <br /><br />
          </template>

          <template v-if="assignmentExtension.newDueDate">
            <span class="font-weight-bold success--text">{{ $t('orginal-due-date') }}:</span>
            {{ `${moment(courseLikeAssignment.dueDate).format('ddd, MMM DD [at] h:mm a')}` }}<br />
            <span class="font-weight-bold success--text">{{ $t('new-due-date') }}: </span>
            {{ `${moment(assignmentExtension.newDueDate).format('ddd, MMM DD [at] h:mm a')}` }}
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="modals.showOriginalDates = false">
            {{ $t('assignmentPage.buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {Course, CourseSection} from '@/courses/models';
import moment from 'moment';
import {QUIZ} from '@/router/route-names';
import Assignment from '@/assignments/models/Assignment';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {getGradeColorClass} from '@/grades/utils/grade-color';
import {hasResponses} from '@/assignments/api/assignments';
import SConfirmationDialog from '@/common/components/SConfirmationDialog';
import DisplayAssignmentGrade from '@/assignments/components/AssignmentGrade';
import AssignmentGrade from '@/grades/models/AssignmentGrade';

export default {
  name: 'CourseLikeAssignmentInfo',
  components: {
    SConfirmationDialog,
    AssignmentGrade: DisplayAssignmentGrade,
  },
  props: {
    missingFormData: {
      type: Array,
      default: () => [],
    },
    assignment: {
      type: Assignment,
      required: true,
    },
    assignmentGrade: {
      type: [AssignmentGrade, String],
      default: null,
    },
    courseLikeAssignments: {
      type: Array,
      required: true,
    },
    canUpdateAssignment: {
      type: Boolean,
      default: false,
    },
    canAssignAssignment: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Course,
      required: true,
    },
    isAssignmentSaving: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNewAssignment: {
      type: Boolean,
      default: true,
    },
    assignmentExtension: {
      type: Object,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    totalAssignmentMarks: {
      type: Number,
      default: 0,
    },
    assignmentOwnerId: {
      type: Number,
      required: false,
      default: null,
    },
    ownerOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    isMarking: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      canDeleteAssignment: false,
      deleteDialogOpened: false,
      courseOrSectionRadio: '',
      showOriginalDueDate: false,
      display: false,
      activeTab: 0,
      date: '',
      time: '',
      visibleDateMenu: false,
      startDateMenu: false,
      dueDateMenu: false,
      headersCourse: [
        {
          text: 'Left',
          align: 'left',
          value: 'label',
          width: '16%',
          sortable: false,
        },
        {text: 'Right', value: 'value', width: '16%', sortable: false},
      ],
      modals: {
        showOriginalDates: false,
      },
    };
  },
  computed: {
    ownerName() {
      return this.assignment.owner?.name ?? this.assignment.course?.name;
    },
    gradeIsLoading() {
      return (
        this.$loadingFlags.isLoading(LoadingFlag.AssignmentAssignmentGrade) ||
        this.$loadingFlags.isLoading(LoadingFlag.AssignmentTaskGrades)
      );
    },
    getGradeColor() {
      return getGradeColorClass(this.assignmentGrade);
    },
    assignmentId() {
      return this.isNewAssignment ? 'new' : parseInt(this.$route.params.assignmentId);
    },
    courseLikeAssignment() {
      // FIXME: not sure why this isn't working when not using updateMode flag
      if (!this.updateMode) {
        return this.courseLikeAssignments.length ? this.courseLikeAssignments[0] : null;
      }
      return this.courseLikeAssignments.length ? this.courseLikeAssignments[0] : null;
    },
    assignedToCourseLikeIds() {
      return this.courseLikeAssignments.map((cla) => cla.courseLikeId);
    },
    assignedToCourseLikes() {
      return this.assignedToCourseLikeIds.map(
        (clId) => Course.find(clId) || CourseSection.find(clId)
      );
    },
    assignmentInfo() {
      let dueDate,
        assignedTo,
        startDate,
        totalMarks,
        grade,
        owner = undefined;

      if (this.courseLikeAssignment) {
        startDate = {
          label: this.$t('assignmentPage.assignCourseLikesTable.headers.startDate'),
          value: this.checkAllSame('startDate')
            ? 'Various'
            : moment(this.courseLikeAssignment.startDate).format('ddd, MMM DD [at] h:mm a'),
        };

        dueDate = {
          label: this.$t('assignmentPage.assignCourseLikesTable.headers.dueDate'),
          value: this.checkAllSame('dueDate')
            ? 'Various'
            : `${moment(this.courseLikeAssignment.dueDate).format(
                'ddd, MMM DD [at] h:mm a'
              )} (${moment(this.courseLikeAssignment.dueDate).fromNow()})`,
        };

        if (this.assignmentExtension) {
          if (this.assignmentExtension.newStartDate) {
            startDate.extension = true;
            startDate.value = `${moment(this.assignmentExtension.newStartDate).format(
              'ddd, MMM DD [at] h:mm a'
            )}`;
          }

          if (this.assignmentExtension.newDueDate) {
            dueDate.extension = true;
            dueDate.withinTwoDays = this.isDueDateWith2Days(this.assignmentExtension.newDueDate);
            dueDate.value = `${moment(this.assignmentExtension.newDueDate).format(
              'ddd, MMM DD [at] h:mm a'
            )}
              (${moment(this.assignmentExtension.newDueDate).fromNow()})`;
          }
        } else {
          dueDate.withinTwoDays = this.isDueDateWith2Days(this.courseLikeAssignment.dueDate);
        }

        const getAssignedToCourseLikeNamesForDisplay = () => {
          if (!this.assignedToCourseLikes?.length) {
            return '';
          }

          const sortedCourseLikeAssignmentNames = this.assignedToCourseLikes
            .map((cl) => cl.name)
            .sort((a, b) => a.localeCompare(b));

          if (sortedCourseLikeAssignmentNames.length <= 5) {
            return sortedCourseLikeAssignmentNames.join(', ');
          }

          // If there are more than 5 assigned sections, display the first 5 and then the count of the rest
          return [
            ...sortedCourseLikeAssignmentNames.slice(0, 5),
            `(+${sortedCourseLikeAssignmentNames.length - 5} others)`,
          ].join(', ');
        };

        if (this.canUpdateAssignment || this.canAssignAssignment) {
          assignedTo = {
            label: this.$t('assignmentPage.assignCourseLikesTable.headers.assignedTo'),
            value: getAssignedToCourseLikeNamesForDisplay(),
          };
        }

        totalMarks = {
          label: this.$t('assignmentsPage.totalMarks'),
          value: this.totalAssignmentMarks,
        };

        grade = {
          label: this.$t('assignmentPage.assignCourseLikesTable.headers.grade'),
          value: this.assignmentGrade ? this.assignmentGrade : null,
        };
      }

      if (this.canUpdateAssignment || this.canAssignAssignment) {
        owner = {
          label: this.$t('assignmentPage.assignCourseLikesTable.headers.assignmentOwner'),
          value: this.assignmentOwnerId,
        };
      }

      return [assignedTo, owner, startDate, dueDate, totalMarks, grade].filter((x) => x);
    },
    quizTo() {
      return {
        name: QUIZ,
        params: this.$route.params,
      };
    },
    extensionsRouteTo() {
      return `/courses/${this.course.id}/assignments/${this.assignment.id}/extensions`;
    },
    canViewGradesFor() {
      return (
        !!this.assignment && this.$auth.user.can('viewGradesFor', 'Assignment', this.assignment)
      );
    },
    canGrantExtensionsFor() {
      return (
        !!this.assignment &&
        this.$auth.user.can('grantExtensionsFor', 'Assignment', this.assignment)
      );
    },
  },
  mounted() {
    this.oldCourseLikeAssignmentsData = this.courseLikeAssignmentsAsArray;

    if (!this.updateMode || this.isNewAssignment || !this.canUpdateAssignment) {
      return;
    }

    this.$loadingFlags.loadingHandler(LoadingFlag.AssignmentHasResponses, async () => {
      const res = await hasResponses(Number(this.$route.params.assignmentId));
      this.canDeleteAssignment = !res.data.data.hasResponses;
    });
  },
  methods: {
    saveAssignment(redirectToSettings = false) {
      this.$emit('save-assignment', [false, redirectToSettings]);
    },
    deleteAssignment() {
      return this.$loadingFlags
        .loadingHandler(
          LoadingFlag.DeleteAssignment,
          Assignment.api
            .remove(Number(this.$route.params.assignmentId))
            .then(() => this.$router.push(`/courses/${this.$route.params.courseId}/assignments`))
        )
        .catch(this.$errorReporting.errorDialogHandler);
    },
    isDueDateWith2Days(date) {
      if (date) {
        const now = moment();
        const dueDateAsMoment = moment(date);
        const twoDaysBeforeDueDate = dueDateAsMoment.clone().subtract(2, 'days');

        if (now.isAfter(twoDaysBeforeDueDate) && now.isBefore(dueDateAsMoment)) {
          return true;
        }
      }
      return false;
    },
    checkAllSame(prop) {
      let lastValue = null;
      let isAllSame = true;
      for (const cla of this.courseLikeAssignments) {
        if (lastValue === null) {
          lastValue = cla;
          continue;
        }
        isAllSame = isAllSame && lastValue[prop] === cla[prop];
        if (!isAllSame) {
          break;
        }
      }
      return !isAllSame;
    },
    openAssignmentSettings(evt) {
      if (this.isAssignmentSaving) {
        return;
      }

      if (this.hasChanges) {
        evt.preventDefault();
        this.saveAssignment(true);
      }
    },
  },
};
</script>

<style scoped>
th {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #d83d0e !important;
}

.disabled-icon {
  pointer-events: all;
}

.card-title {
  font-weight: 600 !important;
  color: #d83d0e !important;
}

.no-course-likes-message {
  font-size: 14px;
  margin-left: 16px;
}

.v-data-table_course_material {
  background-color: transparent !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.info-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-label {
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
}

.info-label--extension {
  margin-bottom: -2px;
}

.info-value {
  font-size: 16px;
  line-height: 1.2;
}
</style>
